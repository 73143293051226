import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faCheckSquare, faDownload, faTrashCan, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, formatDate } from "../../../../common/dates";
import { ComplianceOptionButtons, handleArchiveComplianceOption, handleBulkDeleteComplianceOptions, handleDeleteComplianceOption, handleUndoArchiveComplianceOption } from "../../common";
import { handleAddComplianceFile, handleDownloadFile } from "./common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { useCompany } from "../../../../context/companyProvider";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";

const ComplianceOptionDocumentList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();
    const { compliance } = useCompliance();
    const [archived, setArchived] = useState(false);

    const fetchURL = `${urls.remoteURL}get-item/options/${compliance?.id}/files`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-files`, () => axios.get(fetchURL, { params: { archived } }));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance documents?' };
    }


    useEffect(() => {
        if (!isLoading) {
            setSelectedRows([]);
            refetch();
        }
    }, [archived])


    type TableProps = {
        id: string,
        value: {
            download_name: string,
            originalname: string,
            filename: string,
            mimetype: string,
            size: string,
        },
        link: string,
        created: number,
        updated: number,
        archived: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.originalname', {
            cell: info => <>
                {info.getValue()}
            </>,
            header: 'Filename',
            footer: 'Filename',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('created', {
            cell: info => formatDate({ time: info.getValue() }),
            header: 'Uploaded',
            footer: 'Uploaded',
            meta: {
                className: 'w-40'
            }
        }),
        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <Tooltip content={'Download File'}>
                    <button
                        className="btn btn-orange"
                        onClick={() => { handleDownloadFile(info.getValue(), info.row.original.value.originalname) }}
                    ><span className="sr-only">Download File</span><FontAwesomeIcon icon={faDownload} fixedWidth /></button>
                </Tooltip>

                {!!company.edit_items &&

                    archived ?
                    <>
                        <Tooltip content="Undo Archive">
                            <Button type="button" color="indigo"
                                onClick={() => handleUndoArchiveComplianceOption(info.getValue(), 'File', () => { setSelectedRows([]); refetch(); })}
                            ><span className="sr-only">Undo Archive</span><FontAwesomeIcon icon={faTrashUndo} fixedWidth /></Button>
                        </Tooltip>
                        <Tooltip content="Remove File">
                            <Button type="button" color="red"
                                onClick={() => handleDeleteComplianceOption(info.getValue(), 'File', () => { setSelectedRows([]); refetch(); })}
                            ><span className="sr-only">Remove File</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                        </Tooltip>
                    </>
                    :
                    <Tooltip content="Archive File">
                        <Button type="button" color="red"
                            onClick={() => handleArchiveComplianceOption(info.getValue(), 'File', () => { setSelectedRows([]); refetch(); })}
                        ><span className="sr-only">Archive File</span><FontAwesomeIcon icon={faBoxArchive} fixedWidth /></Button>
                    </Tooltip>



                }
            </div>
        )
    }


    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            filename: row.value.filename,
            mimetype: row.value.mimetype,
            size: row.value.size,
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="space-y-2 text-right mb-5">
                    <div className="flex gap-x-2 justify-end">
                        {/* Add New */}
                        {!!company.edit_items &&
                            <Button color="green" className=" uppercase rounded-full px-6"
                                onClick={() => { handleAddComplianceFile(compliance?.id as string, refetch) }}
                            >
                                Add New
                            </Button>
                        }

                        <Button
                            onClick={() => { setArchived(!archived) }}
                            color={!archived ? 'gray' : 'rhinoBlue'}
                            className={`uppercase rounded-full px-6 space-x-2`}
                        >
                            <FontAwesomeIcon icon={!archived ? faSquare : faCheckSquare} />
                            <span>Archived</span>
                        </Button>
                        <DownloadDataButton downloadFilename={`${compliance?.name}-files`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                    {compliance?.options && <ComplianceOptionButtons options={compliance?.options as string[]} />}
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_items && archived ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Files', () => { setSelectedRows([]); refetch(); })
                                    }} >Remove</Button> : null
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionDocumentList;