import React from "react";
import axios from "axios";
import { myToast, urls } from "../../common";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup';

import { faBiohazard, faFileUpload, faQuestionCircle, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import fileDownload from "js-file-download";
import DocumentAddForm from "./AddForm";
import DocumentBulkUploadForm from "./BulkUpload";


export const handleDownloadFile = ({ documentID, filename }: { documentID: string | string[], filename: string }) => {
    if (!filename) {
        myToast({ title: 'Document not found?', colour: 'red', icon: faQuestionCircle, timing: 3 })
        return;
    }
    const fetchURL = typeof documentID === 'string' ? `document/${documentID}/download` : `documents/download/zip`;

    axios.get(`${urls.remoteURL}${fetchURL}`, { params: { documentIDs: documentID }, responseType: 'blob' })
        .then(({ data, headers }) => {
            const contentType = headers["content-type"];

            if (contentType.startsWith("image/") || contentType === "application/pdf" || contentType.startsWith("text/")) {
                // Preview file in a new tab
                const filePreviewUrl = URL.createObjectURL(data);
                window.open(filePreviewUrl, "_blank");
            } else {
                // Download file
                fileDownload(data, filename || "download");
            }

        })
        .catch(async error => {
            let responseObj = JSON.parse(await error.response.data.text());
            myToast({
                title: 'Error',
                message: responseObj.message,
                icon: faFileUpload,
                colour: 'red', timing: 5
            });
        });
}

export const handleAddDocumentFolder = ({ cp, folderID }: { cp: Function, folderID?: string }) => {
    Swal.fire({
        title: "Create Folder",
        input: "text",
        inputAttributes: {
            autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Add",
        showLoaderOnConfirm: true,
        preConfirm: async (result) => {
            try {
                return axios.post(`${urls.remoteURL}document-folder`, { name: result, folderID }).then((x) => {
                    return x.data
                }).catch((err) => {
                    Swal.showValidationMessage(err.response.data.message ?? `Problem Document Folder`)
                    return false;
                });
            } catch (error) {
                Swal.showValidationMessage(`Request failed: ${error}`);
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({
                title: 'Saved',
                icon: faFileUpload,
                colour: 'green', timing: 3
            });
            if (typeof cp === 'function') { cp(); }
        }
    });
}




export const handleAddDocument = ({ cp, companyID, folderID }: { cp: Function, companyID?: string, folderID?: string }) => {
    const MySwal = withReactContent(Swal)

    let files: File[] | null;

    const handleBulkFilesUpload = (uploadedFiles: File[] | null) => {
        files = uploadedFiles;
    }


    MySwal.fire({
        title: <p>Upload Documents</p>,
        html: <DocumentBulkUploadForm folderID={folderID} inputID="addDocumentFormJSON" setFiles={handleBulkFilesUpload} />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        customClass: {
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            MySwal.resetValidationMessage();
            if (!files) {
                MySwal.showValidationMessage(`Please Select a file`)
                return false;
            }

            const data = JSON.parse((document.getElementById("addDocumentFormJSON") as HTMLInputElement).value);
            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`files[]`, file);
            });

            for (const key of Object.keys(data)) { formData.append(key, data[key]); }
            if (companyID) { formData.append('companyID', companyID); }

            return axios.post(`${urls.remoteURL}documents/bulk-upload`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((x) => {
                return x.data
            }).catch((err) => {
                MySwal.showValidationMessage(err.response.data.message ?? `Problem uploading Document`)
                return false;
            });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({
                title: 'Saved',
                icon: faFileUpload,
                colour: 'green', timing: 3
            });
            if (typeof cp === 'function') { cp(); }
        }
    })
}

export const handleDeleteDocument = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete document, this is perminant!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete Document!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}documents`, { data: [id] })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({
                        title: 'Deleted',
                        icon: faThumbsUp,
                        colour: 'green', timing: 3
                    });
                })
                .catch((error) => {
                    myToast({
                        title: 'Error',
                        message: error.response.data.message || 'Error deleting document?',
                        icon: faBiohazard,
                        colour: 'red', timing: 3
                    });
                });

        }
    })
}

export const handleBulkDeleteDocuments = (ids: string[], refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete all selected documents, this is perminant!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, delete selected documents!`,
        focusConfirm: false,
        focusCancel: true,
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}documents`, { data: ids })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({
                        title: 'Deleted',
                        icon: faThumbsUp,
                        colour: 'green', timing: 3
                    });
                })
                .catch((error) => {
                    myToast({
                        title: 'Error',
                        message: error.response.data.message || 'Error deleting document?',
                        icon: faBiohazard,
                        colour: 'red', timing: 3
                    });
                });

        }
    })
}
