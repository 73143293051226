import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faCheckSquare, faTrashCan, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, _now, formatDate } from "../../../../common/dates";
import { handleAddComplianceRenewal, handleUpdateComplianceRenewal } from "./common";
import { ComplianceOptionButtons, handleArchiveComplianceOption, handleBulkDeleteComplianceOptions, handleDeleteComplianceOption, handleUndoArchiveComplianceOption } from "../../common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { useCompany } from "../../../../context/companyProvider";
import { DatePickerButton } from "../../../../components/form/DatePicker";
import { fromUnixTime, getUnixTime } from "date-fns";

const ComplianceOptionRenewalList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();
    const { compliance } = useCompliance();
    const [archived, setArchived] = useState(false);

    const fetchURL = `${urls.remoteURL}get-item/options/${compliance?.id}/renewals`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-renewals`, () => axios.get(fetchURL, { params: { archived } }));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance renewals?' };
    }

    useEffect(() => {
        if (!isLoading) {
            setSelectedRows([]);
            refetch();
        }
    }, [archived])


    type TableProps = {
        id: string,
        value: {
            // renewalDate: number,
        },
        date_start: number,
        date_expire: number,
        created: number,
        updated: number,
        archived: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('date_start', {
            cell: info => <>
                {info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a'}
                {/* <span className="block md:hidden text-sm font-normal">Users: {info.row.original.users}</span> */}
            </>,
            header: 'Start',
            footer: 'Start',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('date_expire', {
            cell: info => <span className={`${info.getValue() === 0 ? 'bg-gray-300' : info.getValue() <= _now() ? `bg-red-500` : info.getValue() <= _DaysAway(14) ? 'bg-orange-500' : 'bg-green-500'} px-2 py-1 text-white rounded-full`}>{info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a'}</span>,
            header: 'Renewal',
            footer: 'Renewal',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('created', {
            cell: info => info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a',
            header: 'Created',
            footer: () => 'Created',
            meta: {
                className: 'w-40'
            }
        }),
        ... !!company.edit_items ? [
            columnHelper.accessor('id', {
                header: 'Actions',
                footer: 'Actions',
                cell: info => actionCell(info),
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    className: 'w-28 text-center'
                }
            }),
        ] : []
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <Tooltip content="Edit Renewal">
                    <DatePickerButton
                        id={info.row.original.id}
                        value={fromUnixTime(info.row.original.date_expire)}
                        onChange={(newDate) => {
                            handleUpdateComplianceRenewal(info.row.original.id, getUnixTime(newDate), refetch)
                        }}
                        colour="blue"
                    />
                </Tooltip>

                {!!company.edit_items &&
                    archived ?
                    <>
                        <Tooltip content="Undo Archive">
                            <Button type="button" color="indigo"
                                onClick={() => handleUndoArchiveComplianceOption(info.getValue(), 'Renewal', () => { setSelectedRows([]); refetch(); })}
                            ><span className="sr-only">Undo Archive</span><FontAwesomeIcon icon={faTrashUndo} fixedWidth /></Button>
                        </Tooltip>
                        <Tooltip content="Delete Renewal">
                            <Button type="button" color="red"
                                onClick={() => handleDeleteComplianceOption(info.getValue(), 'Renewal', () => { setSelectedRows([]); refetch(); })}
                            ><span className="sr-only">Delete Renewal</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                        </Tooltip>
                    </>
                    :
                    <Tooltip content="Archive Renewal">
                        <Button type="button" color="red"
                            onClick={() => handleArchiveComplianceOption(info.getValue(), 'Renewal', () => { setSelectedRows([]); refetch(); })}
                        ><span className="sr-only">Archive Renewal</span><FontAwesomeIcon icon={faBoxArchive} fixedWidth /></Button>
                    </Tooltip>
                }

            </div>
        )
    }

    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            start: formatDate({ time: row.date_start, formatStr: 'd/M/yyyy' }),
            expire: formatDate({ time: row.date_expire, formatStr: 'd/M/yyyy' }),
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="space-y-2 text-right mb-5">
                    <div className="flex gap-x-2 justify-end">
                        {/* Add New */}
                        {!!company.edit_items &&
                            <Button color="green" className=" uppercase rounded-full px-6"
                                onClick={() => { handleAddComplianceRenewal(compliance?.id as string, refetch) }}
                            >
                                Add New
                            </Button>

                        }

                        <Button
                            onClick={() => { setArchived(!archived) }}
                            color={!archived ? 'gray' : 'rhinoBlue'}
                            className={`uppercase rounded-full px-6 space-x-2`}
                        >
                            <FontAwesomeIcon icon={!archived ? faSquare : faCheckSquare} />
                            <span>Archived</span>
                        </Button>
                        <DownloadDataButton downloadFilename={`${compliance?.name}-renewals`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                    {compliance?.options && <ComplianceOptionButtons options={compliance?.options as string[]} />}
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_items && archived ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Renewals', () => { setSelectedRows([]); refetch(); })
                                    }} >DELETE</Button> : null
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionRenewalList;