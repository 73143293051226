import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, formatDate } from "../../../../common/dates";
import { handleAddComplianceCourse } from "./common";
import { ComplianceOptionButtons, handleBulkDeleteComplianceOptions, handleDeleteComplianceOption } from "../../common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { useCompany } from "../../../../context/companyProvider";
import { PropsCompliance } from "../../types";

const ComplianceOptionCourseList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();
    const { compliance, setRefresh } = useCompliance();

    const fetchURL = `${urls.remoteURL}get-item/options/${compliance?.id}/course`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-course`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance courses?' };
    }



    type TableProps = {
        id: string,
        value: {
            course: {
                id: string,
                name: string,
                code: string,
            },
            required: boolean,
            deadline: number,
        }
        created: number,
        updated: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.course.code', {
            cell: info => info.getValue() || 'n/a',
            header: 'Code',
            footer: 'Code',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('value.course.name', {
            cell: info => info.getValue(),
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        // columnHelper.accessor('value.required', {
        //     cell: info => <FontAwesomeIcon icon={faAsterisk} className={`${info.getValue() ? 'text-red-700' : 'text-gray-600'}`} />,
        //     header: 'Required',
        //     footer: 'Required',
        //     sortingFn: 'alphanumeric',
        // }),

        // columnHelper.accessor('value.deadline', {
        //     cell: info => info.getValue() > 0 ? formatDate({ time: info.getValue(), formatStr: 'd/M/yyyy' }) : 'n/a',
        //     header: 'Deadline',
        //     footer: 'Deadline',
        //     sortingFn: 'alphanumeric',
        // }),

        columnHelper.accessor('created', {
            cell: info => info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a',
            header: () => 'Created',
            footer: () => 'Created',
            meta: {
                className: 'w-40'
            }
        }),
        ...!!company.edit_items ? [
            columnHelper.accessor('id', {
                header: 'Actions',
                footer: 'Actions',
                cell: info => actionCell(info),
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    className: 'w-28 text-center'
                }
            }),
        ] : [],
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <Tooltip content="Delete Renewal">
                    <Button type="button" color="red"
                        onClick={() => handleDeleteComplianceOption(info.getValue(), 'Renewal', () => { setSelectedRows([]); refetch(); setRefresh(prev => !prev); })}
                    ><span className="sr-only">Delete Category</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                </Tooltip>
            </div>
        )
    }

    const [downloadData, setDownloadData] = useState<any[] | undefined>()

    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            name: row.value.course.code,
            email: row.value.course.name,
            required: row.value.required ? 'Yes' : 'No',
            deadline: row.value.deadline > 0 ? formatDate({ time: row.value.deadline, formatStr: 'd/M/yyyy' }) : 'n/a',
            created: row.created > 0 ? formatDate({ time: row.created, formatStr: 'd/M/yyyy' }) : 'n/a',
        }))
    }

    useEffect(() => {
        if (data?.data) { setDownloadData(parseDownloadData(data?.data)); }
    }, [data?.data])

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="space-y-2 text-right mb-5">
                    <div className="flex gap-x-2 justify-end">
                        {/* Add New */}
                        {!!company.edit_items &&
                            <Button color="green" className=" uppercase rounded-full px-6"
                                onClick={() => { handleAddComplianceCourse(compliance as PropsCompliance, () => { refetch(); setRefresh(prev => !prev); }) }}
                            >
                                Add New
                            </Button>
                        }
                        <DownloadDataButton downloadFilename={`${compliance?.name}-courses`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                    {compliance?.options && <ComplianceOptionButtons options={compliance?.options as string[]} />}
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                selectable: !!company.edit_items, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_items ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Renewals', () => { setSelectedRows([]); refetch(); setRefresh(prev => !prev) })
                                    }} >DELETE</Button> : null
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionCourseList;